<template>
  <div class="p-4 h-100" ref="container">
    <div class="d-flex" style="gap: 0.3em">
      <a-card
        v-for="(option, index) in data"
        :key="index"
        size="small"
        class="m-0"
        :body-style="{ padding: '0.5em' }"
      >
        <template #title>
          <div class="d-flex align-items-center justify-content-between">
            <span>{{ filtersLabel[index] }}</span>
            <a-input
              v-if="isDataAvailable[filtersLabel[index]]"
              :value="series[index].color"
              size="small"
              type="color"
              style="width: 10%; margin-right: 1em"
              @change="(e) => handleColorChange(e.target.value, index)"
            />
          </div>
        </template>
        <template #extra>
          <close-outlined
            class="text-danger"
            style="cursor: pointer"
            @click="handleRemoveFilter(index)"
          />
        </template>
        <div class="d-flex flex-column" style="gap: 0.3em">
          <a-select
            v-model:value="option.task"
            class="w-100"
            show-search
            placeholder="Select Operation"
            :options="taskOptions"
            :filter-option="filterOption"
          />
          <a-popconfirm
            title="Do you want to select this date for other tasks?"
            :visible="showConfirm && index === 0"
            ok-text="Yes"
            cancel-text="No"
            @confirm="handleCopySelectedDates"
            @cancel="handleClose"
          >
            <a-range-picker
              v-model:value="option.dateTime"
              show-time
              :placeholder="[
                'Start date (yyyy-mm-dd)',
                'End date (yyyy-mm-dd)',
              ]"
              class="w-100"
              @ok="datePanelChange(index)"
              @openChange="(status) => onChange(status, index)"
            />
          </a-popconfirm>

          <a-select
            v-model:value="option.devices"
            mode="multiple"
            class="w-100"
            placeholder="Select Device(s)"
            :options="deviceOptions"
            :filter-option="filterOption"
            :max-tag-count="1"
          />
        </div>
      </a-card>
      <a-button
        v-if="data.length < 4"
        size="small"
        type="primary"
        class="ml-2"
        @click="handleAddFilter"
      >
        <template #icon>
          <plus-outlined />
        </template>
      </a-button>
    </div>
    <a-button
      type="primary"
      class="mt-2"
      :loading="isFetchingCycles"
      :disabled="!areFilterSelected"
      @click="handleGetData"
    >
      Get Data
    </a-button>
    <a-divider orientation="left" class="mt-3"> Dashboard </a-divider>
    <grouped-bar-chart
      :key="series"
      :categories="taskStepsIntersection"
      :series="series.filter((el) => el.data)"
      :height="chartHeight"
    />
  </div>
</template>
<script>
import { CloseOutlined, PlusOutlined } from '@ant-design/icons-vue';
import dayjs from 'dayjs';
import dateHelper from 'src/components/shared/Helpers/dateHelper';
import { dateTimeFormat } from 'src/config/date-format-config';
import traceMixin from 'src/mixins/analyticsTrace';
import spaceMixin from 'src/mixins/handleSpace';
import TaskService from 'src/services/tasks';
import TelemetryService from 'src/services/telemetry';
import { getSortedDevice } from 'src/utils/device';
import {
  getIndexToStepMapping,
  getSortedTask,
  getTaskNameAndProcess,
} from 'src/utils/task';
import { mapActions, mapGetters } from 'vuex';
import GroupedBarChart from './GroupedBarChart.vue';
export default {
  components: { GroupedBarChart, CloseOutlined, PlusOutlined },
  mixins: [spaceMixin, traceMixin],
  inject: ['toast'],

  data() {
    return {
      data: [
        {
          task: null,
          dateTime: [dateHelper._getDate(), dateHelper._getDate()],
          devices: [],
        },
        {
          task: null,
          dateTime: [dateHelper._getDate(), dateHelper._getDate()],
          devices: [],
        },
      ],
      statsOptions: [
        { label: 'Cycle Stats', value: 'Cycle Stats' },
        { label: 'Average Step Duration', value: 'Average Step Duration' },
        { label: 'Cycle Time Distribution', value: 'Cycle Time Distribution' },
        { label: 'Missed Steps', value: 'Missed Steps' },
        {
          label: 'Non-Value Add Time By Session',
          value: 'Non-Value Add Time By Session',
        },
        { label: 'Individual Cycle Time', value: 'Individual Cycle Time' },
        { label: 'Cycle Count By Session', value: 'Cycle Count By Session' },
        {
          label: 'Individual Cycle Timestamps By Date',
          value: 'Individual Cycle Timestamps By Date',
        },
      ],
      selectedStatsOption: 'Cycle Stats',
      chartsData: {
        'Cycle Stats': [],
        'Average Step Duration': [],
        'Cycle Time Distribution': [],
        'Missed Steps': [],
        'Non-Value Add Time By Session': [],
        'Individual Cycle Time': [],
        'Cycle Count By Session': [],
        'Individual Cycle Timestamps By Date': [],
      },

      taskStepsIntersection: [],
      series: [],
      filtersLabel: {
        0: 'A',
        1: 'B',
        2: 'C',
        3: 'D',
      },
      showConfirm: false,
      isDateSelected: false,
      COLORS: ['#2caffe', '#544fc5', '#00e272', '#fe6a35'],
      chartHeight: 0,
    };
  },

  computed: {
    ...mapGetters([
      'organization',
      'allTasks',
      'devices',
      'isFetchingCycles',
      'deviceDisplayNameToSerialNumMap',
      'deviceSerialNumToDisplayNameMap',
    ]),

    taskOptions() {
      return getSortedTask(this.allTasks);
    },

    deviceOptions() {
      return getSortedDevice(this.devices);
    },

    areFilterSelected() {
      return this.data.every((el) => el.task && el.devices[0]);
    },

    isDataAvailable() {
      return this.series.reduce((res, el) => {
        res[el.name] = el.color;
        return res;
      }, {});
    },
  },

  watch: {
    devices() {
      this.populateFilters();
    },
  },

  created() {
    this.getAllTasks();
    this.getOrgDevices(this.organization);
  },

  mounted() {
    this.computeChartHeight();
  },

  methods: {
    ...mapActions(['getAllTasks', 'getOrgDevices', 'setIsFetchingCycles']),

    computeChartHeight() {
      const el = this.$refs.container;
      let height = el.getBoundingClientRect().height;
      for (const child of el.children) {
        height = height - child.getBoundingClientRect().height;
      }
      this.chartHeight = height - 95;
    },

    datePanelChange(index) {
      if (index === 0) this.isDateSelected = true;
    },

    onChange(status, index) {
      if (!status && this.isDateSelected && index === 0)
        this.showConfirm = true;
    },

    handleClose() {
      this.showConfirm = false;
      this.isDateSelected = false;
    },

    handleCopySelectedDates() {
      const temp = [...this.data];
      let dateToCopy;
      temp.forEach((el, idx) => {
        if (idx === 0) {
          dateToCopy = el.dateTime;
          return;
        }
        el.dateTime = [dateToCopy[0], dateToCopy[1]];
      });
      this.data = temp;
      this.handleClose();
    },

    handleColorChange(value, index) {
      const temp = [...this.series];
      temp[index] = {
        ...temp[index],
        color: value,
      };
      this.series = temp;
    },

    handleAddFilter() {
      if (this.data.length === 4) return;
      this.data.push({
        task: null,
        dateTime: [dateHelper._getDate(), dateHelper._getDate()],
        devices: [],
      });
    },

    handleRemoveFilter(index) {
      this.data = this.data.filter((_, idx) => idx !== index);
    },

    async handleGetData() {
      this.setIsFetchingCycles(true);

      const responses = [];
      let tasksDetailsResponses = [];
      const queryParams = [];

      this.data
        .filter((el) => el.task && el.devices.length > 0)
        .forEach((el) => {
          const params = {
            organization: this.organization,
            task: el.task,
            devices: this.getSelectedDevicesForURL(el.devices),
            datetime_start: this.getDateTime(el.dateTime[0]),
            datetime_end: this.getDateTime(el.dateTime[1]),
            response_data_type: JSON.stringify(['cycle_stats', 'missed_steps']),
          };
          const taskDetailsResponse = TaskService.fetchTaskDetails(
            el.task,
            false
          );
          queryParams.push(params);
          tasksDetailsResponses.push(taskDetailsResponse);
          const response = TelemetryService.fetchCyclesDetailsV2(false, params);
          responses.push(response);
        });

      tasksDetailsResponses = await Promise.all(tasksDetailsResponses);
      this.updateTasksDetails(
        tasksDetailsResponses.map(([error, data]) => (error ? null : data))
      );

      let result = await Promise.all(responses);
      result = result.map(([error, data]) => (error ? null : data));
      this.preProcessChartData(result);
      // this.chartsData = updatedData;

      this.updateUrl(queryParams);
      this.setIsFetchingCycles(false);
    },

    updateUrl(queryParams) {
      this.$router.replace({
        name: this.$route.name,
        query: { _info: JSON.stringify(queryParams) },
      });
    },

    populateFilters() {
      const { _info } = this.$route.query;
      if (!_info) return;
      this.data = JSON.parse(_info).map((el) => ({
        ...el,
        dateTime: [
          this.convertDateToISOFormat(el.datetime_start),
          this.convertDateToISOFormat(el.datetime_end),
        ],
        devices: JSON.parse(el.devices).map(
          (d) => this.deviceSerialNumToDisplayNameMap[d]
        ),
      }));
      this.handleGetData();
    },

    convertDateToISOFormat(date) {
      const hoursToAdd =
        new Date().toString().split('GMT').pop().split(' ')[0] / 100;
      return dayjs(
        new Date(date.replace(/-/g, '/')).toISOString(),
        dateTimeFormat
      ).add(hoursToAdd, 'hour');
    },

    getDateTime(date) {
      return `${dayjs(date).format(dateTimeFormat)}`;
    },

    getSelectedDevicesForURL(devices) {
      return JSON.stringify(
        devices.map((d) => this.deviceDisplayNameToSerialNumMap[d])
      );
    },

    updateTasksDetails(response) {
      const allTaskStepsList = [];
      response.forEach((task, index) => {
        if (!task) return;
        const {
          task_detail,
          negativeSteps,
          optional_steps: optionalSteps,
        } = task;

        const { name, processes } = getTaskNameAndProcess(task_detail);
        const indexToStepsMapping = getIndexToStepMapping(processes);
        allTaskStepsList.push(Object.values(indexToStepsMapping));

        this.data[index] = {
          ...this.data[index],
          name,
          indexToStepsMapping,
          negativeSteps,
          optionalSteps,
        };
      });

      this.taskStepsIntersection = this.getIntersectedSteps(allTaskStepsList);
    },

    getIntersectedSteps(lists) {
      const noOfLists = lists.length;
      const stepsFreqDict = {};
      lists.forEach((list) => {
        list.forEach((step) => {
          const _step = step.trim();
          if (!stepsFreqDict[_step]) stepsFreqDict[_step] = 1;
          else stepsFreqDict[_step]++;
        });
      });
      return (
        Object.entries(stepsFreqDict)
          // eslint-disable-next-line no-unused-vars
          .filter(([_, freq]) => freq === noOfLists)
          .map(([step]) => step)
      );
    },

    preProcessChartData(data) {
      const series = [];
      data.forEach((el, idx) => {
        if (!el || el?.body === 'No result found') {
          series.push({
            name: this.filtersLabel[idx],
            color: this.COLORS[idx],
            data: null,
          });
          return;
        }

        const { data, labels } = el.missed_steps;
        const { number_of_cycles } = el.cycle_stats;
        const missedSteps = labels.reduce((result, key, index) => {
          result[key] = data[index];
          return result;
        }, {});

        series.push({
          name: this.filtersLabel[idx],
          color: this.COLORS[idx],
          data: this.taskStepsIntersection
            .map((el) => missedSteps[el] || 0)
            .map((ms) =>
              Number(
                (((number_of_cycles - ms) / number_of_cycles) * 100).toFixed(1)
              )
            ),
        });
        this.series = series;
      });
    },
  },
};
</script>
<style></style>
