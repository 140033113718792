<template>
  <line-analytics />
</template>
<script>
import LineAnalytics from './LineAnalytics.vue';

export default {
  components: {
    LineAnalytics
  }
};
</script>
<style></style>
